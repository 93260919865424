import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import en from 'vuetify/es5/locale/en'

import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#00A15C',
        secondary: '#8bc34a',
        accent: '#cddc39',
        error: '#E57373',
        warning: '#ff9800',
        info: '#00bcd4',
        success: '#009688'
      }
    }
  },
  icons: {
    iconfont: 'mdi'
  },
  lang: {
    locales: { en },
    current: 'en'
  }
})
