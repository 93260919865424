import Vue from 'vue'
import VueRouter from 'vue-router'

import { UserRole } from '../constants'

const Authenticate = () => import('../views/Authenticate.vue')
const Dashboard = () => import('../views/Dashboard.vue')
const Users = () => import('../views/Users.vue')
const Resources = () => import('../views/Resources.vue')
const Views = () => import('../views/Views.vue')
const Statistics = () => import('../views/Statistics.vue')
const Links = () => import('../views/Links.vue')
const Files = () => import('../views/Files.vue')
const AdTemplates = () => import('../views/AdTemplates.vue')
const RulesGroups = () => import('../views/RulesGroups.vue')
const FraudLists = () => import('../views/FraudLists.vue')
const Embedded = () => import('../views/Embedded.vue')

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: { name: 'Statistics' }
  },
  {
    path: '/statistics',
    name: 'Statistics',
    meta: { title: 'Statistics' },
    component: Dashboard,
    children: [
      {
        path: '/users',
        component: Users,
        name: 'Users',
        meta: { title: 'Users' }
      },
      {
        path: '/resources',
        component: Resources,
        name: 'Resources',
        meta: { title: 'Resources' }
      },
      {
        path: '/views',
        component: Views,
        name: 'Views',
        meta: { title: 'Views' }
      },
      {
        path: '/statistics',
        component: Statistics,
        name: 'Statistics',
        meta: { title: 'Statistics' }
      },
      {
        path: '/links',
        component: Links,
        name: 'Links',
        meta: { title: 'Link Generator' }
      },
      {
        path: '/files',
        component: Files,
        name: 'Files',
        meta: { title: 'Media Files' }
      },
      {
        path: '/codes',
        component: AdTemplates,
        name: 'Codes',
        meta: { title: 'Codes' }
      },
      {
        path: '/rules',
        component: RulesGroups,
        name: 'RulesGroups',
        meta: { title: 'Rules Groups' }
      },
      {
        path: '/fraud-lists',
        component: FraudLists,
        name: 'FraudLists',
        meta: { title: 'Fraud lists', auth: { roles: [UserRole.USER_ADMIN] } }
      },
      {
        path: '/embedded',
        component: Embedded,
        name: 'Embedded',
        meta: { title: 'Embedded', auth: { roles: [UserRole.USER_ADMIN] } }
      }
    ]
  },

  {
    path: '/authenticate',
    name: 'authenticate',
    meta: { title: 'Authorization' },
    component: Authenticate
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// eslint-disable-next-line consistent-return
router.beforeEach((to, from, next) => {
  // redirect to login page if user is not logged in and trying to access a restricted page
  const publicPages = ['/authenticate']
  const authRequired = !publicPages.includes(to.path)
  const loggedIn = localStorage.getItem('auth_token_default')

  if (authRequired && !loggedIn) {
    return next('/authenticate')
  }

  function hasQueryParams(route) {
    return !!Object.keys(route.query).length
  }

  if (!hasQueryParams(to) && hasQueryParams(from)) {
    next({ name: to.name, query: from.query })
  } else {
    next()
  }
})

export default router
