import gql from 'graphql-tag'
import moment from 'moment'
import { createDefaultClient } from '../../../vue-apollo'

const defaultClient = createDefaultClient()

/**
 * @deprecated Since version 2.2.14. Use 'fetchStatistics' service instead.
 */
const fetchStatistics = async (
  { commit },
  { resourceId, viewId, dimensions, metrics, limit, filters, offset, order }
) => {
  const res = await defaultClient.query({
    fetchPolicy: 'no-cache',
    query: gql`
      query (
        $resourceId: ID!
        $viewId: ID!
        $filters: [GroupFilterInput!]!
        $dimensions: [String!]!
        $metrics: [String!]!
        $order: OrderInput
        $pagination: PaginationInput!
      ) {
        stats(
          resourceId: $resourceId
          viewId: $viewId
          filters: $filters
          dimensions: $dimensions
          metrics: $metrics
          order: $order
          pagination: $pagination
        ) {
          count
          rows
        }
      }
    `,
    variables: {
      resourceId,
      viewId,
      dimensions,
      metrics,
      filters,
      order,
      pagination: { limit: limit || 15, offset: offset || 0 }
    }
  })
  commit('SET_STATS', res.data.stats.rows)
  return res.data.stats
}

/**
 * @deprecated Since version 2.2.14. Use 'getFilterExpressions' service instead.
 */
const fetchExpressions = async ({ commit }, { resourceId, viewId, dimension, filters, limit }) => {
  const res = await defaultClient.query({
    fetchPolicy: 'no-cache',
    query: gql`
      query (
        $resourceId: ID!
        $viewId: ID!
        $dimension: String!
        $filters: [GroupFilterInput!]!
        $limit: Float
      ) {
        expressions(
          resourceId: $resourceId
          viewId: $viewId
          dimension: $dimension
          filters: $filters
          limit: $limit
        )
      }
    `,
    variables: {
      resourceId,
      viewId,
      dimension,
      filters,
      limit
    }
  })
  commit('SET_ACTIVE_EXPRESSIONS', { expressions: res.data.expressions, dimension })
}

const setDateFilter = ({ commit }, { dateRange }) => {
  const sortedDateRange = dateRange.sort((a, b) => moment(a).unix() - moment(b).unix())
  commit('SET_DATE_FILTER', { dateFilters: sortedDateRange })
}

const setDimensionFilter = ({ commit }, { dimension, expressions }) => {
  commit('SET_DIMENSION_FILTER', { dimension, expressions })
}

const resetDimensionsFilters = ({ commit }) => {
  commit('RESET_DIMENSIONS_FILTERS')
}

const setActiveColumns = ({ commit }, columns) => {
  commit('SET_ACTIVE_COLUMNS', columns)
}

const setPage = ({ commit }, page) => commit('SET_PAGE', page)

const setLimit = ({ commit }, limit) => commit('SET_LIMIT', limit)

const setOrder = ({ commit }, order) => commit('SET_ORDER', order)

const setFullscreen = ({ commit }) => {
  commit('SET_FULLSCREEN')
}

export default {
  fetchStatistics,
  fetchExpressions,
  setDateFilter,
  setDimensionFilter,
  resetDimensionsFilters,
  setActiveColumns,
  setPage,
  setLimit,
  setOrder,
  setFullscreen
}
