import gql from 'graphql-tag'
import { createDefaultClient } from '../../../vue-apollo'

const defaultClient = createDefaultClient()

const createView = async ({ dispatch }, { resourceId, data }) => {
  const res = await defaultClient.mutate({
    fetchPolicy: 'no-cache',
    mutation: gql`
      mutation createView($resourceId: ID!, $data: CreateViewInput!) {
        createView(resourceId: $resourceId, data: $data) {
          id
          name
          created
          updated
          isMaster
          groupFilters {
            matchType
            filters {
              value
              matchType
              expressions
            }
            groups {
              matchType
              filters {
                value
                matchType
                expressions
              }
              groups {
                matchType
                filters {
                  value
                  matchType
                  expressions
                }
                groups {
                  matchType
                  filters {
                    value
                    matchType
                    expressions
                  }
                }
              }
            }
          }
        }
      }
    `,
    variables: {
      resourceId,
      data
    }
  })
  dispatch('resources/fetchResources', null, { root: true })
  return res.data.createView
}

const updateView = async ({ dispatch }, { resourceId, viewId, data }) => {
  const res = await defaultClient.mutate({
    fetchPolicy: 'no-cache',
    mutation: gql`
      mutation updateView($resourceId: ID!, $viewId: ID!, $data: UpdateViewInput!) {
        updateView(resourceId: $resourceId, viewId: $viewId, data: $data) {
          id
          resource {
            id
            name
          }
          name
          dimensions
          created
          updated
          groupFilters {
            matchType
            filters {
              value
              matchType
              expressions
            }
            groups {
              matchType
              filters {
                value
                matchType
                expressions
              }
              groups {
                matchType
                filters {
                  value
                  matchType
                  expressions
                }
                groups {
                  matchType
                  filters {
                    value
                    matchType
                    expressions
                  }
                }
              }
            }
          }
        }
      }
    `,
    variables: {
      resourceId,
      viewId,
      data
    }
  })
  dispatch('resources/fetchResources', null, { root: true })
  return res.data.updateView
}

const deleteView = async ({ dispatch }, { resourceId, viewId }) => {
  const res = await defaultClient.mutate({
    fetchPolicy: 'no-cache',
    mutation: gql`
      mutation deleteView($resourceId: ID!, $viewId: ID!) {
        deleteView(resourceId: $resourceId, viewId: $viewId) {
          id
          resource {
            id
          }
        }
      }
    `,
    variables: { resourceId, viewId }
  })
  dispatch('resources/fetchResources', null, { root: true })
  return res.data
}

const filterViews = async ({ commit }, { where, pagination }) => {
  const res = await defaultClient.query({
    fetchPolicy: 'no-cache',
    query: gql`
      query getView($where: ViewsFilterInput, $pagination: PaginationInput) {
        views(where: $where, pagination: $pagination) {
          count
          rows {
            id
            name
            dimensions
            resource {
              name
              id
            }
            metrics
            defaultColumns
            created
            updated
            isMaster
            groupFilters {
              matchType
              filters {
                value
                matchType
                expressions
              }
              groups {
                matchType
                filters {
                  value
                  matchType
                  expressions
                }
                groups {
                  matchType
                  filters {
                    value
                    matchType
                    expressions
                  }
                  groups {
                    matchType
                    filters {
                      value
                      matchType
                      expressions
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
    variables: {
      where: {
        resourceId: where.resourceId
      },
      pagination: {
        limit: pagination.limit,
        offset: pagination.offset
      }
    }
  })
  commit('SET_FILTERED_VIEWS', res.data.views.rows)
}

export default {
  createView,
  filterViews,
  updateView,
  deleteView
}
