<template>
  <router-view />
</template>

<script>
export default {
  name: 'App',
  watch: {
    $route(to) {
      document.title = to.meta.title || 'Admon'
    }
  }
}
</script>
<style>
@font-face {
  font-family: "Gotham";
  src: local("Gotham"),
   url(./assets/fonts/Gotham-Medium.ttf) format("truetype");
}
@font-face {
  font-family: "Gotham Light";
  src: local("Gotham Light"),
   url(./assets/fonts/Gotham-Light.ttf) format("truetype");
}
@font-face {
  font-family: "Gotham Book";
  src: local("Gotham Book"),
   url(./assets/fonts/Gotham-Book.ttf) format("truetype");
}
</style>
